import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const CardActionArea = ({ children, ...rest }) => (
  <div {...rest} className="fpl__card-action-area">
    {children}
  </div>
);

CardActionArea.propTypes = {
  /** Sets the Card content */
  children: PropTypes.node.isRequired,
};

export default CardActionArea;
