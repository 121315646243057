import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const CardCallToAction = ({ children, ...rest }) => (
  <>
    <hr className="fpl__card-call-to-action--hr" />
    <div {...rest} className="fpl__card-call-to-action">
      {children}
    </div>
  </>
);

CardCallToAction.propTypes = {
  /** Sets the Card content */
  children: PropTypes.node.isRequired,
};

export default CardCallToAction;
