import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./style.css";

const Card = ({ children, link, ...rest }) => {
  const jsRedirect = link
    ? () => {
      window.location = link;
    }
    : null;
  return (
    <button
      type="button"
      {...rest}
      className={classnames(["fpl__card", link && "fpl__card--link"])}
      onClick={jsRedirect}
    >
      {children}
    </button>
  );
};

Card.propTypes = {
  /** Sets the Card content */
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
};

Card.defaultProps = {
  link: null,
};

export default Card;
