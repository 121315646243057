import React from "react";
import PropTypes from "prop-types";
import ReactMasonryCss from "react-masonry-css";
import "./style.css";

const CardGroup = ({ children, maxColumns, ...rest }) => (
  <ReactMasonryCss
    {...rest}
    breakpointCols={{
      default: maxColumns,
      1200: maxColumns - 1 > 0 ? maxColumns - 1 : 1,
      900: maxColumns - 2 > 0 ? maxColumns - 2 : 1,
    }}
    className="fpl__card-group"
    columnClassName="fpl__card-group--column"
  >
    {children}
  </ReactMasonryCss>
);

CardGroup.propTypes = {
  children: PropTypes.node.isRequired,
  maxColumns: PropTypes.number,
};

CardGroup.defaultProps = {
  maxColumns: 3,
};

export default CardGroup;
