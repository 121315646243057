import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Button from "../components/button";
import Card from "../components/card";
import CardGroup from "../components/cardGroup";
import CardImage from "../components/cardImage";
import CardActionArea from "../components/cardActionArea";
import CardCallToAction from "../components/cardCallToAction";
import Layout from "../components/layout";
import ParallaxImage from "../components/parallaxImage";
import FadeOutText from "../components/fadeOutText";
import SEO from "../components/seo";

const CaseStudies = ({
  data: {
    allPrismicCaseStudy: { edges },
  },
}) => (
  <>
    <SEO title="Case Studies" />
    <Layout>
      <ParallaxImage
        height="halfScreen"
        url="https://images.unsplash.com/15/swirl.JPG?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >
        <CardGroup maxColumns={2}>
          {edges.map(
            ({
              node: {
                data: {
                  title: {
                    raw: [{ text: title }],
                  },
                  abstract: {
                    raw: [{ text: abstract }],
                  },
                  cover_image: { alt, url },
                },
                uid,
              },
            }) => (
              <Card key={uid} link={`/case-studies/${uid}`}>
                <CardImage url={url} alt={alt} />
                <CardActionArea>
                  <h3>{title}</h3>
                  <FadeOutText>
                    <p>{abstract}</p>
                  </FadeOutText>
                </CardActionArea>
                <CardCallToAction>
                  <Button href={`/case-studies/${uid}`}>Read</Button>
                  <Button href="/contact">Contact</Button>
                </CardCallToAction>
              </Card>
            ),
          )}
        </CardGroup>
      </ParallaxImage>
    </Layout>
  </>
);

export default CaseStudies;

CaseStudies.propTypes = {
  data: PropTypes.object,
};

CaseStudies.defaultProps = {
  data: {},
};

export const query = graphql`
  query {
    allPrismicCaseStudy {
      edges {
        node {
          uid
          data {
            title {
              raw {
                text
              }
            }
            abstract {
              raw {
                text
              }
            }
            cover_image {
              alt
              url
            }
          }
        }
      }
    }
  }
`;
