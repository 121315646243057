import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const FadeOutText = ({ children }) => (
  <div className="fpl__fade-out-text">{children}</div>
);

FadeOutText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FadeOutText;
