import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const CardImage = ({ children, url }) => (
  <div className="fpl__card-image" style={{ backgroundImage: `url(${url})` }}>
    {children}
  </div>
);

CardImage.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};

CardImage.defaultProps = {
  children: "",
};

export default CardImage;
